
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/projectinfo";

@Options({})
export default class extends Vue {
  private value = "";
  private deptId = "";
  private deptInfo = { user: [], dept: [] };
  protected goBack() {
    this.$router.go(-1);
  }

  protected workerInfo(item: any) {
    this.$router.push({
      path: "/app/PersonrecordDetail",
      query: { userId: item.userId },
    });
  }

  getreqdata(id: any) {
    api
      .mobilepersondept({
        deptId: id,
        projectId: this.$store.state.project.projectinfo.projectId,
      })
      .then((res) => {
        this.deptInfo = res.data;
      });
  }

  @Watch("$route", { immediate: true })
  private routerChange(route: any) {
    this.deptId = route.query && route.query.deptId;
    api
      .mobilepersondept({
        projectId: this.$store.state.project.projectinfo.projectId,
        deptId: this.deptId,
      })
      .then((res) => {
        this.deptInfo = res.data;
      });
  }

  @Watch("value")
  onValueChanged(newValue: string, oldValue: string) {
    if (newValue === "") {
      api
        .mobilepersondept({
          projectId: this.$store.state.project.projectinfo.projectId,
          deptId: this.deptId,
        })
        .then((res) => {
          this.deptInfo = res.data;
        });
    }
  }

  protected filterUsername(value: any) {
    this.deptInfo.user = this.deptInfo.user.filter((item: any) => {
      if (item.name.indexOf(value) !== -1) {
        return true;
      }
    });
  }

  protected synchronization() {
    this.$toast.loading({
      forbidClick: true,
      message: "同步中",
    });

    const timer = setInterval(() => {
      api
        .syncShengPingTai(this.$store.state.project.projectinfo.projectId)
        .then((res: any) => {
          if (res.code === 0) {
            clearInterval(timer);
            this.$toast.clear();
            setTimeout(() => {
              this.$toast({
                message: res.msg,
                type: "success",
              });
            }, 200);
          } else {
            clearInterval(timer);
            this.$toast.clear();
            setTimeout(() => {
              this.$toast({
                message: res.msg,
                type: "fail",
              });
            }, 200);
          }
        });
    }, 1000);
  }
}
